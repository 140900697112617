import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  InputGroup,
  Tag,
  Text,
  Input,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Header from "../../Components/Common/Header";
import { DatePicker, Dropdown, Modal, TimePicker, Tooltip } from "antd";
import { GiConfirmed } from "react-icons/gi";
import { FaCaretDown, FaRegClock } from "react-icons/fa";
import { MdHourglassTop, MdOutlineDangerous } from "react-icons/md";
import { CgDanger } from "react-icons/cg";
import { IoMdClose } from "react-icons/io";
import { CiCircleQuestion } from "react-icons/ci";
import { Icons } from "../../Assets/icons";
import moment from "moment";
import { CommonAPI } from "../../Apis/commonApi";
import {
  ExhibitorScreens,
  MEETING_REQUEST_STATUS,
  relevanceFields,
} from "../../Constants/data";
import CreateMeetModal from "../../Components/Modals/CreateMeetModal";
import { Images } from "../../Assets/images";
import { useToastWrapper } from "../../Wrapper/toastWrapper";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { disabledDate } from "../../Utils/Validator";
import {
  useLocation,
  useNavigate,
  useNavigation,
  useSearchParams,
} from "react-router-dom";
import { ExhibitorAPI } from "../../Apis/exhibitorAPI";
import { VisitorAPI } from "../../Apis/visitorAPI";
import actions from "../../redux/Actions";
import Loader from "../../Components/Loader/Loader";
import ConfirmationModal from "../../Components/Modals/ConfirmationModal";

type Props = {};

const MeetingList = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalClose = () => setIsModalOpen(false);
  const handleModalOpen = () => setIsModalOpen(true);

  const [isCalenderModalOpen, setIsCalenderModalOpen] = useState(false);
  const handleCalenderModalClose = () => setIsCalenderModalOpen(false);
  const handleCalenderModalOpen = () => setIsCalenderModalOpen(true);
  const [date, setDate] = useState("");
  const [time, setTime] = useState<any>("");
  const [selectedMeet, setSelectedMeet] = useState<any>({});
  const [type, setType] = useState<"new" | "edit">("new");

  const [responder, setResponder] = useState<any>("");

  const { success, error } = useToastWrapper();

  const [meetings, setMeetings] = useState<any>([]);

  const [userPlan, setUserPlan] = useState<any>({});

  const user = useSelector((state: any) => state.user);

  const [meetingCounts, setMeetingCounts] = useState<any>({});

  const [userData, setUserData] = useState<any>({});

  const [userMobileNumber, setUserMobileNumber] = useState<any>("");

  const [todayDate, setTodayDate] = useState<any>("");

  const [isRedirected, setIsRedirected] = useState(false);
  const [activeId, setActiveId] = useState("");

  const itemRef = React.useRef<HTMLDivElement>();

  const meetingContainerRef = React.useRef<HTMLDivElement>();

  const [searchParams] = useSearchParams();

  const [activeMeetingItem, setActiveMeetingItem] = useState<any>({});

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [meetConfirm, setMeetConfirm] = useState(false);
  const handleOpenMeetConfirmModal = (id: any) => {
    setActiveId(id);
    setMeetConfirm(true);
  };
  const handleCloseMeetConfirmModal = () => {
    setMeetConfirm(false);
  };

  const [meetTimeConfirm, setMeetTimeConfirm] = useState(false);
  const handleOpenMeetTimeConfirmModal = () => setMeetTimeConfirm(true);
  const handleCloseMeetTimeConfirmModal = () => {
    setActiveMeetingItem({});
    setMeetTimeConfirm(false);
  };

  const findRecentDate = (data: any) => {
    console.log(searchParams.get("id"));

    if (searchParams.get("id")) {
      let meetId: string = searchParams.get("id") || "";
      console.log(meetId);

      data.some((item: any) => {
        if (item._id == meetId) {
          item.latestMeeting = true;
        }
      });

      setIsRedirected(true);
    } else {
      let today = new Date();
      // let today = new Date('2024-02-22');
      let todayInTime = today.getTime();
      data.some((item: any) => {
        let pDate = new Date(item.proposedDate).getTime();
        if (pDate >= todayInTime) {
          item.latestMeeting = true;
          return item;
        }
      });
    }

    console.log("data..", data);
    return data;
  };

  const compareTimeDifference = (
    time1: string,
    time2: string,
    date1: string,
    date2: string
  ) => {
    const [hours1, minutes1] = time1.split(":").map(Number);
    const [hours2, minutes2] = time2.split(":").map(Number);

    const day1 = new Date(date1).getDate();
    const day2 = new Date(date2).getDate();

    const month1 = new Date(date1).getMonth();
    const month2 = new Date(date2).getMonth();

    const year1 = new Date(date1).getFullYear();
    const year2 = new Date(date2).getFullYear();

    const totalMinutes1 = hours1 * 60 + minutes1;
    const totalMinutes2 = hours2 * 60 + minutes2;

    console.log("date1..", date1);
    console.log("date2..", date2);
    console.log("time1..", time1);
    console.log("time2..", time2);

    if (day1 === day2 && month1 === month2 && year1 === year2) {
      // both times are in same day
      const difference = Math.abs(totalMinutes1 - totalMinutes2);

      if (difference <= 30) {
        return false;
      } else {
        return true;
      }
    } else {
      // meeting exist on different days
      if (hours1 === 23 || hours1 === 0 || hours2 === 23 || hours2 === 0) {
        // meetings booked between 23:00 and 00:00 of different days
        const difference = Math.abs(totalMinutes1 - totalMinutes2);

        if (difference <= 30) {
          return false;
        } else {
          return true;
        }
      }
    }
  };

  const getPlanDetails = async () => {
    try {
      let userId = user && user?.role == "Exhibitor" ? user.user : "";

      if (user && user?.role == "Exhibitor") {
        const [planDetails] = await Promise.all([
          ExhibitorAPI.getEventExhibitorProfile(userId),
        ]);

        if (planDetails) {
          setUserPlan(planDetails?.data?.data);
          console.log("user///", planDetails?.data?.data);
        }
      }
    } catch (err: any) {
      // console.log(err);
    }
  };

  const checkMeetStatus = async (item: any) => {
    setLoading(true);
    try {
      console.log("inside button accept click2 checkMeetStatus()");

      const response = await CommonAPI.CheckMeetingAcceptAllowed(item._id);
      if (response) {
        // console.log("response..", response.data.data);
        const flag = response.data.data.isAllowed;
        if (flag) {
          console.log(
            "inside button accept click2 checkMeetStatus() if condition"
          );

          // allowed to accept meet
          // buttonTypeAcceptClick(item, item._id);
          handleUpdate(item._id, { response: MEETING_REQUEST_STATUS.CONFIRM });
          handleCloseMeetTimeConfirmModal();
        } else {
          console.log(
            "inside button accept click2 checkMeetStatus() else condition"
          );

          // show confirmation and continue on yes
          setActiveMeetingItem(item);
          handleOpenMeetTimeConfirmModal();
        }
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const checkMeetStatusForChange = async (item: any, obj: any) => {
    setLoading(true);
    try {
      const response = await CommonAPI.CheckMeetingChangeAllowed(
        item._id,
        date,
        time
      );
      if (response) {
        // console.log("response..", response.data.data);
        const flag = response.data.data.isAllowed;
        if (flag) {
          // allowed to accept meet
          const res = await CommonAPI.UpdateMeeting(selectedMeet._id, obj);
          if (res) {
            success("Meeting request updated");
            handleCalenderModalClose();
            setDate("");
            setTime("");
            getData();
          }
        } else {
          // show confirmation and continue on yes
          handleOpenMeetTimeConfirmModal();
        }
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getData = async () => {
    setMeetings([]);
    setLoading(true);
    try {
      // const res = await CommonAPI.GetMeeting({
      //   status: status == "total" ? undefined : status,
      // });
      // if (res) {
      //   setMeetings(res.data.data);
      //   setMeetingCounts(res.data.meetingCounts);
      // }

      getPlanDetails();

      const [data2, res] = await Promise.all([
        user && user?.role == "Exhibitor"
          ? ExhibitorAPI.getExhibitorProfile()
          : VisitorAPI.getVisitorProfile(),
        CommonAPI.GetMeeting({
          status: status == "total" ? undefined : status,
        }),
      ]);

      if (data2) {
        setUserData(data2?.data?.data);
        if (user && user?.role == "Exhibitor") {
          setUserMobileNumber(data2?.data?.data.personPhoneNumber);
        } else {
          setUserMobileNumber(data2?.data?.data.visitorPhoneNumber);
        }
      }

      if (res) {
        let response = JSON.parse(JSON.stringify(res.data.data));
        response.sort(
          (a: any, b: any) =>
            new Date(a.proposedDate).getTime() -
            new Date(b.proposedDate).getTime()
        );
        console.log("response...", response);
        console.log("res.data.data...", res.data.data);

        const alteredResponse = findRecentDate(response);

        setMeetings(alteredResponse);
        setMeetingCounts(res.data.meetingCounts);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setMeetings([]);
      setLoading(false);
    }
  };

  const allowCall = (proposedDateStr: string, proposedTime: string) => {
    const today = new Date();
    const proposedDate = new Date(proposedDateStr);

    if (
      today.getDay() === proposedDate.getDay() &&
      today.getMonth() === proposedDate.getMonth() &&
      today.getFullYear() === proposedDate.getFullYear() &&
      today.getHours() >= parseInt(proposedTime.split(":")[0]) &&
      today.getMinutes() >= parseInt(proposedTime.split(":")[1])
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleUpdate = async (id: string, data: any) => {
    try {
      console.log("inside button accept click2 handleUpdate() ");

      const res = await CommonAPI.UpdateMeeting(id, { ...data });
      console.log(res);
      if (res) {
        getData();
        getPlanDetails();
        setActiveId("");
        handleCloseMeetConfirmModal();
        setActiveMeetingItem({});
        success("Meeting request updated");
        console.log("inside button accept click2 handleUpdate() success");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmitEditOrNew = async () => {
    if (!date || !time) return error("Please fill all the fields");

    try {
      const responderId =
        user.user == responder.responderId
          ? responder.requesterId
          : responder.responderId;
      console.log(responderId);

      if (type == "new") {
        const obj = {
          proposedDate: date,
          proposedTime: time,
          eventId: responder.eventId,
        };
        const res = await CommonAPI.PostCreateMeeting(responderId, obj);
        if (res) {
          success("Meeting request updated");
          handleCalenderModalClose();
          setDate("");
          setTime("");
          getData();
          getPlanDetails();
        }
      } else {
        const obj = {
          proposedDate: date,
          proposedTime: time,
          response: MEETING_REQUEST_STATUS.PROPOSE_ALTERNATE_DATE,
        };
        console.log("this is inside handleeditornew..", selectedMeet);
        checkMeetStatusForChange(selectedMeet, obj);
        // const res = await CommonAPI.UpdateMeeting(selectedMeet._id, obj);
        // if (res) {
        //   success("Meeting request updated");
        //   handleCalenderModalClose();
        //   setDate("");
        //   setTime("");
        //   getData();
        // }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCall = async (id: any) => {};

  const isPendingMeetingsPresent = (
    proposedDateStr: string,
    proposedTime: string
  ) => {
    const proposedDate = new Date(proposedDateStr);

    const userId = user.user;

    const pendingMeetings = meetings.filter((m: any) => {
      if (
        m.responderId == userId &&
        (m.responderStatus == MEETING_REQUEST_STATUS.REQUEST_RECEIVED ||
          m.responderStatus == MEETING_REQUEST_STATUS.PUT_ON_HOLD)
      ) {
        if (new Date(m.proposedDate).getTime() < proposedDate.getTime()) {
          return m;
        }
        if (new Date(m.proposedDate).getTime() == proposedDate.getTime()) {
          if (
            parseInt(m.proposedTime.split(":")[0]) <=
              parseInt(proposedTime.split(":")[0]) &&
            parseInt(m.proposedTime.split(":")[1]) <=
              parseInt(proposedTime.split(":")[1])
          ) {
            return m;
          }
        }
      }
    });

    console.log("pendingMeetings..", pendingMeetings);

    if (pendingMeetings.length > userPlan.pendingMeetingsLimit) {
      return true;
    } else {
      return false;
    }
  };

  const isAcceptActionAllowed = (item: any) => {
    // block if 5 or more pending meetings are present before the current meeting
    console.log("inside button accept click2 isAcceptActionAllowed()");

    if (isPendingMeetingsPresent(item.proposedDate, item.proposedTime)) {
      error(
        "Unable to confirm this meeting, as there are atleast " +
          userPlan.pendingMeetingsLimit +
          " meetings before this meeting that are awaiting your response."
      );
      return false;
    }
    // block if total of 50 meetings reached
    if (
      userPlan.acceptedMeetingsCount + userPlan.closedMeetingsCount >=
      userPlan.closedMeetingsLimit
    ) {
      error(
        "Unable to accept a new meeting as you have reached the meeting limit of " +
          userPlan.closedMeetingsLimit +
          " meetings (accepted + closed). To accept a new meeting, please change the status of any of your accepted meetings."
      );
      return false;
    }

    // block if 50 closed meetings reached
    if (userPlan.closedMeetingsCount == userPlan.closedMeetingsLimit) {
      error(
        "You have reached the subscription limit of closed meetings. Please renew your subscription."
      );
      return false;
    }

    return true;
  };

  const changeMeetingRequestStatus = async () => {
    try {
      const obj = {
        proposedDate: date,
        proposedTime: time,
        response: MEETING_REQUEST_STATUS.PROPOSE_ALTERNATE_DATE,
      };
      const res = await CommonAPI.UpdateMeeting(selectedMeet._id, obj);
      if (res) {
        success("Meeting request updated");
        handleCalenderModalClose();
        setDate("");
        setTime("");
        getData();
        setSelectedMeet({});
      }
    } catch (err) {
      console.log(err);
      error("Unable to change meeting!");
    }
  };

  const buttonTypeAcceptClick = (item: any, id: string) => {
    if (user && user.role == "Exhibitor") {
      console.log("inside button accept click2 buttonTypeAcceptClick()");

      if (isAcceptActionAllowed(item)) {
        checkMeetStatus(item);
        // handleUpdate(id, { response: MEETING_REQUEST_STATUS.CONFIRM });
        // handleCloseMeetTimeConfirmModal();
        // setActiveMeetingItem({});
      }
    } else {
      handleUpdate(id, { response: MEETING_REQUEST_STATUS.CONFIRM });
    }
  };

  const ButtonTypeCancel = ({ id }: any) => {
    return (
      <Button
        flex={1}
        size={"md"}
        p={0}
        py={3}
        w={"100%"}
        mt={3}
        bg={"red.100"}
        color={"red.600"}
        onClick={() => {
          handleOpenMeetConfirmModal(id);
          // handleUpdate(id, { response: MEETING_REQUEST_STATUS.CANCELLED });
        }}
      >
        Cancel
      </Button>
    );
  };
  const ButtonTypeHold = ({ id }: any) => {
    return (
      <Button
        flex={1}
        size={"md"}
        p={0}
        py={3}
        w={"100%"}
        mt={3}
        bg={"yellow.100"}
        color={"yellow.600"}
        onClick={() => {
          handleUpdate(id, { response: MEETING_REQUEST_STATUS.PUT_ON_HOLD });
        }}
      >
        Hold
      </Button>
    );
  };
  const ButtonTypeNewRequest = ({ id }: any) => {
    return (
      <Button
        flex={1}
        size={"md"}
        p={0}
        py={3}
        w={"100%"}
        mt={3}
        bg={"green.100"}
        color={"green.600"}
        onClick={() => {
          if (!userMobileNumber)
            return error(
              "Please provide your contact number in the profile page"
            );

          setSelectedMeet(meetings.find((meet: any) => meet._id == id));
          setResponder(meetings.find((meet: any) => meet._id == id));
          handleCalenderModalOpen();
          setType("new");
        }}
      >
        New Request
      </Button>
    );
  };
  const ButtonTypeChange = ({ id, proposedTime, proposedDate, item }: any) => {
    return (
      <Button
        flex={1}
        size={"md"}
        p={0}
        py={3}
        w={"100%"}
        mt={3}
        bg={"orange.100"}
        color={"orange.600"}
        onClick={() => {
          // There is a confirmed meeting within 30 minutes of this meeting. Continue?
          // for (let i = 0; i < meetings.length; i++) {
          //   let currMeeting = meetings[i];
          //   if (
          //     !compareTimeDifference(
          //       currMeeting.proposedTime,
          //       proposedTime,
          //       currMeeting.proposedDate,
          //       proposedDate
          //     )
          //   ) {
          //     return false;
          //   }
          // }
          //checkMeetStatusForChange(item)
          if (!userMobileNumber)
            return error(
              "Please provide your contact number in the profile page"
            );
          if (userPlan.closedMeetingsCount >= userPlan.closedMeetingsLimit) {
            error(
              "You have reached the subscription limit of closed meetings. Please renew your subscription."
            );
          } else {
            let t = meetings.find((meet: any) => meet._id == id);
            if (t) {
              setSelectedMeet(meetings.find((meet: any) => meet._id == id));
              handleCalenderModalOpen();
              setType("edit");
            } else {
              error("Unable to change meeting!");
            }
          }
        }}
      >
        Change
      </Button>
    );
  };
  const ButtonTypeAccept = ({ id, disabled, item }: any) => {
    return (
      <Button
        flex={1}
        size={"md"}
        p={0}
        py={3}
        w={"100%"}
        mt={3}
        bg={"green.100"}
        color={"green.600"}
        onClick={() => {
          console.log("accept");
          if (!userMobileNumber)
            return error(
              "Please provide your contact number in the profile page"
            );

          console.log("inside button accept click1");
          buttonTypeAcceptClick(item, id);
          // let flag = false;
          // for (let i = 0; i < meetings.length; i++) {
          //   let currMeeting = meetings[i];
          //   if (
          //     currMeeting.responderStatus === MEETING_REQUEST_STATUS.CONFIRM &&
          //     (item.responderStatus ===
          //       MEETING_REQUEST_STATUS.REQUEST_RECEIVED ||
          //       item.responderStatus === MEETING_REQUEST_STATUS.PUT_ON_HOLD)
          //   ) {
          //     if (
          //       !compareTimeDifference(
          //         currMeeting.proposedTime,
          //         item.proposedTime,
          //         currMeeting.proposedDate,
          //         item.proposedDate
          //       )
          //     ) {
          //       console.log("flag set...", flag);
          //       flag = true;
          //       break;
          //     }
          //   }
          // }

          // if (flag === true) {
          //   setActiveMeetingItem(item);
          //   handleOpenMeetTimeConfirmModal();
          // } else {
          //   buttonTypeAcceptClick(item, id);
          // }
        }}
        isDisabled={disabled}
      >
        Accept
      </Button>
    );
  };
  const ButtonTypeCall = ({ id }: any) => {
    return (
      <Button
        flex={1}
        size={"md"}
        p={0}
        py={3}
        w={"100%"}
        mt={3}
        bg={"blue.100"}
        color={"blue.600"}
        onClick={() => {
          // handleUpdate(id, { response: MEETING_REQUEST_STATUS.CANCELLED });
          if (!allowCall(id.proposedDate, id.proposedTime)) {
            return error("Call will be enabled on the day of the meeting.");
          }

          if (user.role == "Exhibitor") {
            const resMobile =
              user.user === id.responderDetails.userId
                ? id.requesterDetails.visitorPhoneNumber
                : id.responderDetails.visitorPhoneNumber;

            window.location.href = "tel:" + resMobile;
          } else if (user.role == "Visitor") {
            const resMobile =
              user.user === id.requesterDetails.userId
                ? id.responderDetails.personPhoneNumber
                : id.requesterDetails.personPhoneNumber;

            window.location.href = "tel:" + resMobile;
          }
        }}
      >
        Call
      </Button>
    );
  };

  const meetsStatusArray = [
    {
      label: "All",
      bg: "#E9FAF7",
      status: MEETING_REQUEST_STATUS.TOTAL,
      color: "#03A790",
      icon: <GiConfirmed />,
    },
    {
      label: "Confirmed",
      bg: "#E9FAF7",
      status: MEETING_REQUEST_STATUS.CONFIRM,
      color: "#03A790",
      icon: <GiConfirmed />,
    },
    {
      label: "Request Received",
      bg: "orange.100",
      status: MEETING_REQUEST_STATUS.REQUEST_RECEIVED,
      color: "orange.600",
      icon: <FaRegClock />,
    },
    {
      label: "Response Awaited",
      bg: "orange.100",
      status: MEETING_REQUEST_STATUS.RESPONSE_AWAITED,
      color: "orange.600",
      icon: <FaRegClock />,
    },
    {
      label: "Hold",
      bg: "yellow.100",
      status: MEETING_REQUEST_STATUS.PUT_ON_HOLD,
      color: "yellow.600",
      icon: <MdHourglassTop />,
    },
    {
      label: "Expired",
      bg: "red.100",
      status: MEETING_REQUEST_STATUS.EXPIRED,
      color: "red.600",
      icon: <CgDanger />,
    },
    {
      label: "Cancelled",
      bg: "red.100",
      status: MEETING_REQUEST_STATUS.CANCELLED,
      color: "red.600",
      icon: <MdOutlineDangerous />,
    },
    {
      label: "Closed",
      bg: "red.100",
      status: MEETING_REQUEST_STATUS.CLOSED,
      color: "red.600",
      icon: <IoMdClose />,
    },
  ];

  const buttons = {};
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (status) getData();
  }, [status]);

  useEffect(() => {
    console.log("outside if..", itemRef.current);
    console.log("outside if container..", meetingContainerRef.current);

    if (meetingContainerRef.current && itemRef.current) {
      console.log("inside if..", itemRef.current);

      console.log(
        "meetingContainerRef.current.scrollTop...",
        meetingContainerRef.current.scrollTop
      );
      console.log("itemRef.current.offsetTop..", itemRef.current.offsetTop);

      meetingContainerRef.current.scrollTop = itemRef.current.offsetTop - 180;

      console.log(
        "meetingContainerRef.current.scrollTop after...",
        meetingContainerRef.current.scrollTop
      );
      console.log(
        "itemRef.current.offsetTop after..",
        itemRef.current.offsetTop
      );

      //itemRef.current.style.backgroundColor = 'red';
    }
  }, [meetings]);

  useEffect(() => {
    const s = searchParams.get("status");
    console.log(s);

    let date = new Date();
    setTodayDate(date.getTime());

    if (s) {
      setStatus(s);
    } else {
      setStatus(MEETING_REQUEST_STATUS.TOTAL);
    }

    // console.log("outside if..", itemRef.current)
    // console.log("outside if container..", meetingContainerRef.current)

    // if(meetingContainerRef.current && itemRef.current){
    //   console.log("inside if..", itemRef.current)
    //    meetingContainerRef.current.scrollTop = itemRef.current.offsetTop ;
    //   //itemRef.current.style.backgroundColor = 'red';
    // }

    // const value = document.getElementById('meetingContainer').scrollTop ;
    // if(document){
    //   let parentElement : HTMLElement = document.getElementById('meetingContainer')!;
    //   let child : HTMLElement = document.getElementById('latestMeeting')!;
    //   parentElement.scrollTop = child.offsetTop || 0;
    // }

    if (user && user?.role == "Exhibitor") {
      dispatch(
        actions.exhibitorMenu.set({
          activeMenu: ExhibitorScreens.CALENDAR,
        })
      );
    } else {
      dispatch(
        actions.visitorMenu.set({
          activeMenu: ExhibitorScreens.CALENDAR,
        })
      );
    }
  }, []);

  return (
    <Box
      width={"100%"}
      //minH={"100vh"}
      display={"flex"}
      flexDirection={"column"}
      px={6}
      pt={6}
    >
      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={meetTimeConfirm}
        onOk={handleCloseMeetTimeConfirmModal}
        onCancel={handleCloseMeetTimeConfirmModal}
      >
        <ConfirmationModal
          handleCloseModal={handleCloseMeetTimeConfirmModal}
          handleSubmit={() => {
            if (Object.keys(activeMeetingItem).length > 0) {
              console.log(
                "inside button accept click2 modal confirm click activeMeetingItem...",
                activeMeetingItem
              );

              // accept button clicked
              handleUpdate(activeMeetingItem._id, {
                response: MEETING_REQUEST_STATUS.CONFIRM,
              });
              handleCloseMeetTimeConfirmModal();
              setActiveMeetingItem({});
              // buttonTypeAcceptClick(activeMeetingItem, activeMeetingItem._id);
              // handleCloseMeetTimeConfirmModal();
            } else if (Object.keys(selectedMeet).length > 0) {
              console.log(
                "this is inside confirm 30mins selectedMeet...",
                selectedMeet
              );
              // change button is clicked
              changeMeetingRequestStatus();
              handleCloseMeetTimeConfirmModal();
            } else {
              error("Unable to confirm meeting!");
            }
          }}
          title={"Another meeting exists"}
          message={
            "There is a confirmed meeting within 30 minutes of this meeting. Continue?"
          }
          confirmBtnText={"Yes, Confirm"}
          rejectButtonText={"No"}
        />
      </Modal>
      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={meetConfirm}
        onOk={handleCloseMeetConfirmModal}
        onCancel={handleCloseMeetConfirmModal}
      >
        <ConfirmationModal
          handleCloseModal={handleCloseMeetConfirmModal}
          handleSubmit={() => {
            // navigate("/exhibitor/calendar?id=" + currentMeet);
            handleUpdate(activeId, {
              response: MEETING_REQUEST_STATUS.CANCELLED,
            });
          }}
          title={"Confirm cancellation of meeting"}
          message={
            "The other party would be notified that the meeting is cancelled. Continue cancellation ?"
          }
          confirmBtnText={"Yes, Cancel"}
          rejectButtonText={"No"}
        />
      </Modal>

      <Modal
        footer={null}
        title={""}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        open={isModalOpen}
        closeIcon={null}
      >
        <Box w={"100%"} h={"100%"}>
          <Box
            position={"relative"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            h={"60px"}
          >
            <Text
              fontSize={"20px"}
              fontWeight={700}
              textAlign={"center"}
              color={"black"}
            >
              Select Status
            </Text>
            <Image
              onClick={() => {
                handleModalClose();
              }}
              position={"absolute"}
              src={Icons.cross}
              left={0}
              top={0}
            />
          </Box>
          <Flex direction={"column"} gap={4}>
            {meetsStatusArray?.map((meetStatus, index) => (
              <Button
                key={index}
                flex={1}
                w={"100%"}
                p={0}
                py={3}
                bg={meetStatus.status == status ? meetStatus.bg : "white"}
                color={meetStatus.status == status ? meetStatus.color : "black"}
                _hover={{
                  bg: meetStatus.bg,
                  color: meetStatus.color,
                }}
                display={"flex"}
                justifyContent={"start"}
                gap={2}
                px={3}
                onClick={() => {
                  setStatus(meetStatus.status);
                  handleModalClose();
                }}
              >
                {meetStatus.icon}
                {meetStatus.label}({meetingCounts[meetStatus.status]})
              </Button>
            ))}
          </Flex>
        </Box>
      </Modal>

      {/* New or change meet */}
      <Modal
        footer={null}
        title={""}
        onOk={handleCalenderModalClose}
        onCancel={handleCalenderModalClose}
        open={isCalenderModalOpen}
        closeIcon={null}
      >
        <Box h={"100%"} w={"100%"}>
          <Box
            position={"relative"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            h={"60px"}
          >
            <Text
              fontSize={"20px"}
              fontWeight={700}
              textAlign={"center"}
              color={"black"}
            >
              {/* {type=="edit"?"Edit": "Create"} meeting */}
            </Text>
            <Image
              onClick={() => {
                handleCalenderModalClose();
              }}
              position={"absolute"}
              src={Icons.cross}
              left={0}
              top={0}
            />
          </Box>

          <Flex direction={"column"} justifyContent={"center"}>
            {/* <Flex justifyContent={"center"}>
              <Image src={Images.schedule} alt="schedule" width={"80px"} />
            </Flex> */}
            <Text
              fontSize={"18px"}
              fontWeight={"semibold"}
              mt={3}
              textAlign={"center"}
            >
              Meeting request to{" "}
              {user.user == selectedMeet.requesterId
                ? selectedMeet?.responderCompany
                : selectedMeet.requesterCompany || "Company Name"}
            </Text>
            <FormControl mt={10}>
              <Text fontSize={"sm"} fontWeight={"light"} fontStyle={"italic"}>
                *Fix appointments for telephonic meetings
              </Text>
              <FormLabel>Date</FormLabel>
              <InputGroup>
                <DatePicker
                  renderExtraFooter={() => null}
                  value={date ? dayjs(date) : null}
                  onChange={(time, dateString) => {
                    console.log(time, dateString);
                    setDate(dateString);
                  }}
                  disabledDate={disabledDate}
                  style={{ width: "100%", height: "50px" }}
                  placeholder="Choose Date"
                  superNextIcon={null}
                  inputReadOnly
                  superPrevIcon={null}
                  popupClassName="custom-date-picker-meeting-popup"
                />
              </InputGroup>
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>Time</FormLabel>
              <input
                type="time"
                style={{
                  width: "100%",
                  height: "50px",
                  padding: "0 10px",
                  border: "1px solid #E2E8F0",
                  background: "white",
                }}
                onChange={(e) => {
                  setTime(e.target.value);
                }}
                value={time}
              />
              {/* <InputGroup>
              <TimePicker
                value={time?dayjs(time):null}
                onChange={(date, timeString)=>{
                  console.log(date, timeString);
                  setTime(timeString)
                  
                }}
                onSelect={(time)=>{
                  console.log('sel',dayjs(time).format('h:mm a'));
                  setTime(time)
                }}
                style={{width:'100%', height:'50px'}}
                placeholder="Choose TIme"
                format="h:mm a"
            />
              </InputGroup> */}
            </FormControl>
            <Flex alignItems={"center"} gap={3} mt={"25px"}>
              <Button
                onClick={handleSubmitEditOrNew}
                flex={1}
                size={"md"}
                p={0}
                bg={"secondary.light"}
                color={"secondary.main"}
              >
                Send
              </Button>
              <Button
                onClick={() => {
                  setTime("");
                  setDate("");
                  handleCalenderModalClose();
                }}
                flex={1}
                size={"md"}
                p={0}
                bg={"gray.100"}
                color={"gray.600"}
              >
                Cancel
              </Button>
            </Flex>
          </Flex>
        </Box>
      </Modal>
      <VStack w={"100%"} textAlign={"left"} alignItems={"flex-start"}>
        <Text fontSize={"sm"} fontWeight={400}>
          {user && user.role == "Exhibitor"
            ? userData?.personName
            : userData?.visitorPersonName}
        </Text>
        <Text fontSize={"lg"} fontWeight={500}>
          {user && user.role == "Exhibitor"
            ? userData?.companyName
            : userData?.visitorCompanyName}
        </Text>
      </VStack>

      <Flex mt={8} justifyContent={"end"}>
        <Box>
          <Box p={2}>
            <Button
              shadow={"md"}
              flex={1}
              w={"100%"}
              p={0}
              py={3}
              bg={meetsStatusArray.find((meet) => meet.status == status)?.bg}
              color={
                meetsStatusArray.find((meet) => meet.status == status)?.color
              }
              display={"flex"}
              gap={2}
              px={3}
              onClick={() => {
                handleModalOpen();
              }}
            >
              <GiConfirmed />
              {meetsStatusArray.find((meet) => meet.status == status)?.label} (
              {meetings.length})
              <FaCaretDown />
            </Button>
          </Box>
        </Box>
      </Flex>
      <Flex
        ref={meetingContainerRef as React.LegacyRef<HTMLDivElement>}
        direction={"column"}
        gap={5}
        pt={5}
        height={"75vh"}
        overflowY={"scroll"}
      >
        {meetings?.length != 0 ? (
          meetings?.map((item: any, key: number) => {
            if (
              (user.user == item.responderId
                ? item.responderStatus
                : item.requesterStatus) == MEETING_REQUEST_STATUS.CONFIRM
            ) {
              return (
                <Box
                  bg={item.latestMeeting && isRedirected ? "#E9FAF7" : "white"}
                  shadow={"md"}
                  rounded={"lg"}
                  p={3}
                  ref={
                    item.latestMeeting
                      ? (itemRef as React.LegacyRef<HTMLDivElement>)
                      : null
                  }
                >
                  <Flex justifyContent={"space-between"} alignItems={"center"}>
                    <Text fontSize={"sm"}>
                      Date: {moment(item.proposedDate).format("DD/MMM/YY")}{" "}
                      {item.proposedTime}
                    </Text>
                    {status == MEETING_REQUEST_STATUS.TOTAL ? (
                      <Text fontSize={"sm"}>
                        {
                          meetsStatusArray?.find(
                            (s) =>
                              s.status ==
                              (user.user == item.responderId
                                ? item.responderStatus
                                : item.requesterStatus)
                          )?.label
                        }
                      </Text>
                    ) : null}
                  </Flex>
                  <Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    gap={2}
                    mt={3}
                  >
                    <Flex>
                      <Text fontSize={18} fontWeight={600}>
                        {user.user == item.responderId
                          ? item.requesterCompany
                          : item.responderCompany}
                      </Text>
                      {/* <Tooltip title="Created on 12 August 2023"> */}
                      {/* <CiCircleQuestion
                        style={{ height: "16px", width: "16px" }}
                        // cursor={"pointer"}
                      /> */}
                      {/* </Tooltip> */}
                    </Flex>
                    {/* <Box
                      w={4}
                      h={4}
                      rounded={"full"}
                      bg={
                        relevanceFields.find(
                          (s) => s.status == item.relevanceStatus
                        )?.color
                      }
                    ></Box> */}
                  </Flex>

                  <Flex gap={4}>
                    {/* {showCallButton(item.proposedDate, item.proposedTime) && (
                      
                    )} */}
                    <ButtonTypeCall id={item} />
                    <ButtonTypeChange item={item} id={item._id} />
                    <ButtonTypeCancel id={item._id} />
                  </Flex>
                </Box>
              );
            } else if (
              (user.user == item.responderId
                ? item.responderStatus
                : item.requesterStatus) ==
              MEETING_REQUEST_STATUS.REQUEST_RECEIVED
            ) {
              return (
                <Box
                  bg={item.latestMeeting && isRedirected ? "#F7DBB4" : "white"}
                  shadow={"md"}
                  rounded={"lg"}
                  p={3}
                  ref={
                    item.latestMeeting
                      ? (itemRef as React.LegacyRef<HTMLDivElement>)
                      : null
                  }
                >
                  <Flex justifyContent={"space-between"} alignItems={"center"}>
                    <Text fontSize={"sm"}>
                      Date: {moment(item.proposedDate).format("DD/MMM/YY")}{" "}
                      {item.proposedTime}
                    </Text>
                    {status == MEETING_REQUEST_STATUS.TOTAL ? (
                      <Text fontSize={"sm"}>
                        {
                          meetsStatusArray?.find(
                            (s) =>
                              s.status ==
                              (user.user == item.responderId
                                ? item.responderStatus
                                : item.requesterStatus)
                          )?.label
                        }
                      </Text>
                    ) : null}
                  </Flex>
                  <Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    gap={2}
                    mt={3}
                  >
                    <Flex>
                      <Text fontSize={18} fontWeight={600}>
                        {user.user == item.responderId
                          ? item.requesterCompany
                          : item.responderCompany}
                      </Text>
                      {/* <Tooltip title="Created on 12 August 2023"> */}
                      {/* <CiCircleQuestion
                        style={{ height: "16px", width: "16px" }}
                        // cursor={"pointer"}
                      /> */}
                      {/* </Tooltip> */}
                    </Flex>
                    {/* <Box
                      w={4}
                      h={4}
                      rounded={"full"}
                      bg={
                        relevanceFields.find(
                          (s) => s.status == item.relevanceStatus
                        )?.color
                      }
                    ></Box> */}
                  </Flex>

                  <Flex gap={4}>
                    <ButtonTypeAccept
                      item={item}
                      id={item._id}
                      disabled={
                        new Date(item.proposedDate).getTime() >= todayDate
                          ? false
                          : true
                      }
                    />
                    <ButtonTypeChange item={item} id={item._id} />
                    <ButtonTypeHold id={item._id} />
                  </Flex>
                </Box>
              );
            } else if (
              (user.user == item.responderId
                ? item.responderStatus
                : item.requesterStatus) ==
              MEETING_REQUEST_STATUS.RESPONSE_AWAITED
            ) {
              return (
                <Box
                  bg={item.latestMeeting && isRedirected ? "#F7DBB4" : "white"}
                  shadow={"md"}
                  rounded={"lg"}
                  p={3}
                  ref={
                    item.latestMeeting
                      ? (itemRef as React.LegacyRef<HTMLDivElement>)
                      : null
                  }
                >
                  <Flex justifyContent={"space-between"} alignItems={"center"}>
                    <Text fontSize={"sm"}>
                      Date: {moment(item.proposedDate).format("DD/MMM/YY")}{" "}
                      {item.proposedTime}
                    </Text>
                    {status == MEETING_REQUEST_STATUS.TOTAL ? (
                      <Text fontSize={"sm"}>
                        {
                          meetsStatusArray?.find(
                            (s) =>
                              s.status ==
                              (user.user == item.responderId
                                ? item.responderStatus
                                : item.requesterStatus)
                          )?.label
                        }
                      </Text>
                    ) : null}
                  </Flex>
                  <Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    gap={2}
                    mt={3}
                  >
                    <Flex>
                      <Text fontSize={18} fontWeight={600}>
                        {user.user == item.responderId
                          ? item.requesterCompany
                          : item.responderCompany}
                      </Text>
                      {/* <Tooltip title="Created on 12 August 2023"> */}
                      {/* <CiCircleQuestion
                        style={{ height: "16px", width: "16px" }}
                        // cursor={"pointer"}
                      /> */}
                      {/* </Tooltip> */}
                    </Flex>
                    {/* <Box
                      w={4}
                      h={4}
                      rounded={"full"}
                      bg={
                        relevanceFields.find(
                          (s) => s.status == item.relevanceStatus
                        )?.color
                      }
                    ></Box> */}
                  </Flex>

                  <Flex gap={4}>
                    <ButtonTypeCancel id={item._id} />
                  </Flex>
                </Box>
              );
            } else if (
              (user.user == item.responderId
                ? item.responderStatus
                : item.requesterStatus) == MEETING_REQUEST_STATUS.PUT_ON_HOLD
            ) {
              return (
                <Box
                  bg={item.latestMeeting && isRedirected ? "#F7DBB4" : "white"}
                  shadow={"md"}
                  rounded={"lg"}
                  p={3}
                  ref={
                    item.latestMeeting
                      ? (itemRef as React.LegacyRef<HTMLDivElement>)
                      : null
                  }
                >
                  <Flex justifyContent={"space-between"} alignItems={"center"}>
                    <Text fontSize={"sm"}>
                      Date: {moment(item.proposedDate).format("DD/MMM/YY")}{" "}
                      {item.proposedTime}
                    </Text>
                    {status == MEETING_REQUEST_STATUS.TOTAL ? (
                      <Text fontSize={"sm"}>
                        {
                          meetsStatusArray?.find(
                            (s) =>
                              s.status ==
                              (user.user == item.responderId
                                ? item.responderStatus
                                : item.requesterStatus)
                          )?.label
                        }
                      </Text>
                    ) : null}
                  </Flex>
                  <Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    gap={2}
                    mt={3}
                  >
                    <Flex>
                      <Text fontSize={18} fontWeight={600}>
                        {user.user == item.responderId
                          ? item.requesterCompany
                          : item.responderCompany}
                      </Text>
                      {/* <Tooltip title="Created on 12 August 2023"> */}
                      {/* <CiCircleQuestion
                        style={{ height: "16px", width: "16px" }}
                        // cursor={"pointer"}
                      /> */}
                      {/* </Tooltip> */}
                    </Flex>
                    {/* <Box
                      w={4}
                      h={4}
                      rounded={"full"}
                      bg={
                        relevanceFields.find(
                          (s) => s.status == item.relevanceStatus
                        )?.color
                      }
                    ></Box> */}
                  </Flex>

                  <Flex gap={4}>
                    <ButtonTypeAccept
                      id={item._id}
                      disabled={
                        new Date(item.proposedDate).getTime() >= todayDate
                          ? false
                          : true
                      }
                    />
                    <ButtonTypeChange id={item._id} item={item} />
                  </Flex>
                </Box>
              );
            } else if (
              (user.user == item.responderId
                ? item.responderStatus
                : item.requesterStatus) == MEETING_REQUEST_STATUS.EXPIRED
            ) {
              return (
                <Box
                  bg={item.latestMeeting && isRedirected ? "#F7DBB4" : "white"}
                  shadow={"md"}
                  rounded={"lg"}
                  p={3}
                  ref={
                    item.latestMeeting
                      ? (itemRef as React.LegacyRef<HTMLDivElement>)
                      : null
                  }
                >
                  <Flex justifyContent={"space-between"} alignItems={"center"}>
                    <Text fontSize={"sm"}>
                      Date: {moment(item.proposedDate).format("DD/MMM/YY")}{" "}
                      {item.proposedTime}
                    </Text>
                    {status == MEETING_REQUEST_STATUS.TOTAL ? (
                      <Text fontSize={"sm"}>
                        {
                          meetsStatusArray?.find(
                            (s) =>
                              s.status ==
                              (user.user == item.responderId
                                ? item.responderStatus
                                : item.requesterStatus)
                          )?.label
                        }
                      </Text>
                    ) : null}
                  </Flex>
                  <Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    gap={2}
                    mt={3}
                  >
                    <Flex>
                      <Text fontSize={18} fontWeight={600}>
                        {user.user == item.responderId
                          ? item.requesterCompany
                          : item.responderCompany}
                      </Text>
                      {/* <Tooltip title="Created on 12 August 2023"> */}
                      {/* <CiCircleQuestion
                        style={{ height: "16px", width: "16px" }}
                        // cursor={"pointer"}
                      /> */}
                      {/* </Tooltip> */}
                    </Flex>
                    {/* <Box
                      w={4}
                      h={4}
                      rounded={"full"}
                      bg={
                        relevanceFields.find(
                          (s) => s.status == item.relevanceStatus
                        )?.color
                      }
                    ></Box> */}
                  </Flex>

                  {/* <Flex gap={4}>
                    <ButtonTypeChange id={item._id} />
                  </Flex> */}
                </Box>
              );
            } else if (
              (user.user == item.responderId
                ? item.responderStatus
                : item.requesterStatus) == MEETING_REQUEST_STATUS.CANCELLED
            ) {
              return (
                <Box
                  bg={item.latestMeeting && isRedirected ? "#F7DBB4" : "white"}
                  shadow={"md"}
                  rounded={"lg"}
                  p={3}
                  ref={
                    item.latestMeeting
                      ? (itemRef as React.LegacyRef<HTMLDivElement>)
                      : null
                  }
                >
                  <Flex justifyContent={"space-between"} alignItems={"center"}>
                    <Text fontSize={"sm"}>
                      Date: {moment(item.proposedDate).format("DD/MMM/YY")}{" "}
                      {item.proposedTime}
                    </Text>
                    {status == MEETING_REQUEST_STATUS.TOTAL ? (
                      <Text fontSize={"sm"}>
                        {
                          meetsStatusArray?.find(
                            (s) =>
                              s.status ==
                              (user.user == item.responderId
                                ? item.responderStatus
                                : item.requesterStatus)
                          )?.label
                        }
                      </Text>
                    ) : null}
                  </Flex>
                  <Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    gap={2}
                    mt={3}
                  >
                    <Flex>
                      <Text fontSize={18} fontWeight={600}>
                        {user.user == item.responderId
                          ? item.requesterCompany
                          : item.responderCompany}
                      </Text>
                      {/* <Tooltip title="Created on 12 August 2023"> */}
                      {/* <CiCircleQuestion
                        style={{ height: "16px", width: "16px" }}
                        // cursor={"pointer"}
                      /> */}
                      {/* </Tooltip> */}
                    </Flex>
                    {/* <Box
                      w={4}
                      h={4}
                      rounded={"full"}
                      bg={
                        relevanceFields.find(
                          (s) => s.status == item.relevanceStatus
                        )?.color
                      }
                    ></Box> */}
                  </Flex>

                  <Flex gap={4}>
                    <ButtonTypeChange id={item._id} item={item} />
                  </Flex>
                </Box>
              );
            } else if (
              (user.user == item.responderId
                ? item.responderStatus
                : item.requesterStatus) == MEETING_REQUEST_STATUS.CLOSED
            ) {
              return (
                <Box
                  bg={item.latestMeeting && isRedirected ? "#F7DBB4" : "white"}
                  shadow={"md"}
                  rounded={"lg"}
                  p={3}
                  ref={
                    item.latestMeeting
                      ? (itemRef as React.LegacyRef<HTMLDivElement>)
                      : null
                  }
                >
                  <Flex justifyContent={"space-between"} alignItems={"center"}>
                    <Text fontSize={"sm"}>
                      Date: {moment(item.proposedDate).format("DD/MMM/YY")}{" "}
                      {item.proposedTime}
                    </Text>
                    {status == MEETING_REQUEST_STATUS.TOTAL ? (
                      <Text fontSize={"sm"}>
                        {
                          meetsStatusArray?.find(
                            (s) =>
                              s.status ==
                              (user.user == item.responderId
                                ? item.responderStatus
                                : item.requesterStatus)
                          )?.label
                        }
                      </Text>
                    ) : null}
                  </Flex>
                  <Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    gap={2}
                    mt={3}
                  >
                    <Flex>
                      <Text fontSize={18} fontWeight={600}>
                        {user.user == item.responderId
                          ? item.requesterCompany
                          : item.responderCompany}
                      </Text>
                      {/* <Tooltip title="Created on 12 August 2023"> */}
                      {/* <CiCircleQuestion
                        style={{ height: "16px", width: "16px" }}
                        // cursor={"pointer"}
                      /> */}
                      {/* </Tooltip> */}
                    </Flex>
                    {/* <Box
                      w={4}
                      h={4}
                      rounded={"full"}
                      bg={
                        relevanceFields.find(
                          (s) => s.status == item.relevanceStatus
                        )?.color
                      }
                    ></Box> */}
                  </Flex>

                  <Flex gap={4}>
                    {/* <ButtonTypeNewRequest id={item._id} /> */}
                  </Flex>
                </Box>
              );
            } else return null;
          })
        ) : (
          <Flex justifyContent={"center"} alignItems={"center"}>
            {/* <Text fontSize={18} fontWeight={600}>
              No Meetings Found
            </Text> */}
            {loading && <Loader />}
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default MeetingList;
